import { useNavigate } from 'react-router-dom';
import logo from '../images/logo/TD Green Logo.png';
import { useState } from 'react';
import Loader from '../components/loading/loading';
import axios from 'axios';

function ContactUs() {
  const navigate = useNavigate();
  const [loading, setIsloading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    predefinedQuestion: "",
    usePredefined: false,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(null); // State to track which FAQ is open

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.message) {
      return; // Optionally show a message for validation if needed
    }

    setIsloading(true);

    try {
      const response = await axios.post('https://theradermbackend.onrender.com/api/email/send', {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });

      // Handle successful response (e.g., show a success message, clear the form)
      console.log(response.data);

      // Show success message
      setShowSuccess(true);

      // Hide the success message after a delay (e.g., 5 seconds)
      setTimeout(() => setShowSuccess(false), 5000);
    } catch (error) {
      // Handle error
      console.error('Error sending message:', error);
    } finally {
      setIsloading(false);
    }
  };

  const predefinedQuestions = [
    { question: "What ingredients are used in your products?", answer: "Our products are made with a blend of high-quality ingredients, including natural oils, plant extracts, and vitamins that nourish and protect your skin and hair. We also highlight locally sourced Ethiopian ingredients where possible." },
    { question: "Are your products suitable for all skin and hair types?", answer: "Yes, our products are formulated to suit a variety of skin and hair types, including sensitive, dry, oily, and normal" },
    { question: "Can I use your products on children or sensitive skin?", answer: "Yes, many of our products are gentle enough for children and sensitive skin, but always check the label and do a patch test before use." },
    { question: "How can I contact customer service for questions or feedback?", answer: "You can reach us through email, WhatsApp, or direct message on Instagram. We’re here to help!" },
    { question: "Where can I buy your products in Ethiopia?", answer: "You can find our products in stores across Ethiopia, including major cities and towns." },
  ];

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index); // Toggle between showing and hiding the answer
  };

  return (
    <>
      {/* Navbar */}
      <div className="w-full sticky top-0 z-50">
        <nav className="flex items-center justify-between bg-white p-4 shadow-md">
          <div className="flex items-center w-full lg:w-auto">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                src={logo}
                alt="logo"
                className="h-16 w-16 md:h-20 md:w-20 object-contain"
              />
            </div>
            <button
              className="lg:hidden text-gray-900 focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
          <div className="hidden lg:block">
            <button
              onClick={() => navigate('/')}
              className="text-black hover:text-shampoo font-bold py-2 px-6 rounded-lg transform transition-all duration-200 hover:scale-105 hover:shadow-lg active:scale-95"
            >
              &larr; Home
            </button>
          </div>
        </nav>
      </div>
      
      <div className=' bg-gradient-to-b from-green-100 to-green-50'>
        <div className='p-5 py-10'>
          <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">Get in Touch</h1>
          <p className="text-gray-600 mb-10 text-center max-w-xl mx-auto">
            Whether you have a question about our products or want to become a distributor, we’re here to help!
          </p>
        </div>

        {/* FAQ & Form Section */}
        <div className="min-h-screen bg-white py-12 px-4 flex items-center justify-center">
          <div className="flex flex-col lg:flex-row gap-12 justify-center items-center w-full max-w-screen-xl">
            {/* FAQ Section */}
            <div className="lg:w-1/2 flex flex-col space-y-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center lg:text-center">FAQ</h2>
              {predefinedQuestions.map((item, index) => (
                <div key={index} className="bg-white rounded-md shadow-md hover:shadow-lg transition-shadow duration-300">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="w-full text-left p-4 font-semibold text-gray-800 bg-green-50 rounded-t-md hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center justify-between"
                  >
                    <span>{item.question}</span>
                    <svg
                      className={`w-6 h-6 transform transition-transform duration-200 ${openFAQ === index ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {openFAQ === index && (
                    <div className="p-4 text-gray-700 bg-green-50 rounded-b-md">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Contact Form Section */}
            <div className="lg:w-1/2 w-full flex flex-col items-center justify-center relative">
              {/* Form Background Blur Effect */}
              {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50">
                  <Loader />
                </div>
              )}

              {showSuccess &&
                // Success Card
               ( <div className="fixed top-10 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-md z-50">
                  <p>Message sent successfully! We’ll get back to you soon.</p>
                </div>)
                }
                <form
                  onSubmit={handleSubmit}
                  className={`w-full max-w-lg bg-white p-8 rounded-lg shadow-xl relative z-20 ${loading ? 'blur-sm' : ''}`}
                >
                  {/* Name Field */}
                  <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-500"
                      placeholder="Enter your name"
                      required
                    />
                  </div>

                  {/* Email Field */}
                  <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-500"
                      placeholder="Enter your email"
                      required
                    />
                  </div>

                  {/* Message Field */}
                  <div className="mb-6">
                    <label className="block text-gray-700 font-semibold mb-2">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-green-500 focus:border-green-500"
                      placeholder="Enter your message"
                      rows="4"
                      required
                    />
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full bg-green-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-green-400 transform transition-all duration-200 hover:scale-105"
                  >
                    Submit
                  </button>
                </form>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
