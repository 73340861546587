import React from 'react';
import body_wash from '../images/products/body-care/Body-Wash.png';
import pattern from '../images/patterns/TDwebbackground-02.png'
import bodWas from '../images/prod_ngs/TheraDermBackgroundPictures-07.png'

function Bodywash() {
  return (
    <div className=" py-16  border-x-8 border-lotion_border " >
    {/* Header sec */}
    <div className='bg-' >
        <div className='flex flex-col justify-center items-center ' >
          <div className='pt-5' >
            <h1 className='text-orange_text md:text-6xl font-semibold' > BODY WASH </h1>
          </div>
          <div className='mb-5   w-96 h-6 rounded-lg flex flex-col justify-center align-middle py-4 ' >
            <h1 className='text-body_washbg md:text-sm  text-center capitalize ' > WITH HONEY & GLYCERIN </h1>
          </div>
        </div>
      </div>

    <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden"
    style={{  backgroundImage: `url(${bodWas})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'}} 
    >
            {/* Text Section */}
      <div className='flex flex-col justify-center  shadow-xl w-full md:w-[600px] ' >
        <div className="p-10 text-justify">
        <div className='py-5 text-center' >
            <h1 className='text-orange_text md:text-3xl font-semibold' > BODY WASH </h1>
          </div>
          
            <p className="text-gray-100 text-sm px-5">
            THERA DERM’s <span className='text-orange_text font-semibold' > BODY WASH </span>contains active ingredients that create an
            intensive moisturizing and skin-softening sensation. It calms and soothes
            skin irritations, and itchiness, leaving the skin delicately scented.
            </p>
        </div>


        <div className='flex justify-center align-bottom items-end relative font-playfair '>
                    <div className=' flex justify-center items-center align-bottom' >
                    <h2 className='text-gray-100 text-lg '> "for <span className='text-orange_text'> refreshed </span> & <span className='text-orange_text' > hydrated </span> skin " </h2>
                    </div>
            </div>


      </div>
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={body_wash}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={body_wash}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={body_wash}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-white text-xs'>Available in <span className='text-orange_text' > 300ml </span> & <span className='text-orange_text' > 500ml </span> </h2>
            </div>
        </div>
        
      </div>
   </div>

  )
}

export default Bodywash