import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { GiMailbox } from 'react-icons/gi';
import { TfiEmail } from 'react-icons/tfi';
import { FaTiktok, FaXTwitter } from 'react-icons/fa6';
import logo from '../images/logo/fotterlogo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate()
  const [responseMessage, setResponseMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const SubscribeEmail = async (email) => {
    try {
      setLoading(true);
      setResponseMessage(''); // Clear any previous messages
      
      const response = await axios.post('https://theradermbackend.onrender.com/api/subscribe', { email });
      const successMessage = response?.data?.message;

      if (response.status === 201) {
        setResponseMessage(successMessage || 'Subscribed successfully!');
        setShowSuccess(true);
        setEmail(''); // Clear email input field
      } 
    } catch (error) {
      // Extract a meaningful error message
      const errorMessage =
        error.response?.data?.message || 'Failed to subscribe. Please try again later.';
      setResponseMessage(errorMessage);
      setShowSuccess(true);
      
    } finally {
      setLoading(false);

      // Show success/error message for 5 seconds
      setTimeout(() => {
        setShowSuccess(false);
        setResponseMessage('');
      }, 5000);
    }
  };

  return (
    <>
      {/* Success Card */}
      {showSuccess && (
        <div className="fixed top-10 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-md z-50">
          <p>{responseMessage}</p>
        </div>
      )}

      {/* Footer */}
      <div>
        <footer className="bg-gray-100 text-gray-700 py-12 px-8 md:px-16">
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Quick Links Section */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Quick Links</h3>
              <ul className="space-y-3">
                <li>
                  <a href="#home" className="text-gray-600 hover:text-gray-900 hover:underline transition">Home</a>
                </li>
                <li>
                  <a href="#theraderm" className="text-gray-600 hover:text-gray-900 hover:underline transition">About Us</a>
                </li>
                <li>
                  <a href="#products" className="text-gray-600 hover:text-gray-900 hover:underline transition">Products</a>
                </li>
                <li>
                  <h1 onClick={ () => navigate('/contactus')}className="text-gray-600 hover:text-gray-900 hover:underline transition hover:cursor-pointer">Contact</h1>
                </li>
              </ul>
            </div>

            {/* Contact Us Section */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Contact Us</h3>
              <div className="flex items-center space-x-4 mb-4">
                <div className="border-r pr-4">
                  <img
                    src={logo}
                    alt="Logo"
                    className="w-28"
                  />
                </div>
                <div>
                  <p className="text-gray-700">
                    <strong>P.O. Box 14572</strong><br />
                    Addis Ababa, Ethiopia
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-2 mt-8 ml-5 group">
                <TfiEmail className="text-gray-600 text-xl transition group-hover:text-red-500" />
                <p className="text-sm text-gray-600">
                  <a
                    href="mailto:info@theradermethiopia.com"
                    className="text-gray-600 transition group-hover:text-gray-900 group-hover:underline"
                  >
                    info@theradermethiopia.com
                  </a>
                </p>
              </div>
            </div>

            {/* Follow Us Section */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Follow Us</h3>
              <div className="flex space-x-5">
                <div className="flex space-x-2">
                  <a
                    href="https://www.tiktok.com/@theraderm.ethio?_t=8q2IwhFl3Yc&_r=1"
                    className="text-gray-600 hover:text-black transition"
                    aria-label="TikTok"
                     target='_blank'
                  >
                    <FaTiktok className="text-3xl hover:scale-110 transition" />
                  </a>
                  <a
                    href="https://web.facebook.com/profile.php?id=61565927445697"
                    className="text-gray-600 hover:text-blue-600 transition"
                    aria-label="Facebook"
                     target='_blank'
                  >
                    <FaFacebook className="text-3xl hover:scale-110 transition" />
                  </a>
                  <a
                    href="https://www.youtube.com/@theradermethiopia"
                    className="text-gray-600 hover:text-red-600 transition"
                    aria-label="Youtube"
                     target='_blank'
                  >
                    <FaYoutube className="text-3xl hover:scale-110 transition" />
                  </a>
                  <a
                    href="https://www.instagram.com/theraderm.ethio?igsh=MWtyOWJsZjRscGQ5ag=="
                    className="text-gray-600 hover:text-pink-500 transition"
                    aria-label="Instagram"
                     target='_blank'
                  >
                    <FaInstagram className="text-3xl hover:scale-110 transition" />
                  </a>
                  <a
                    href="https://x.com/theradermethio?s=21"
                    className="text-gray-600 hover:text-black transition"
                    aria-label="X"
                     target='_blank'
                  >
                    <FaXTwitter className="text-3xl hover:scale-110 transition" />
                  </a>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Subscribe to Our Newsletter</h3>
              <p className="text-sm text-gray-600 mb-4">Stay updated with the latest news and offers. Subscribe now!</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  SubscribeEmail(email);
                }}
                className="flex flex-col sm:flex-row gap-4"
              >
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)} // Update email state
                  className="w-full px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <button
                  type="submit"
                  className={`px-6 py-2 bg-hair_green text-white rounded-lg transition ${
                    loading ? 'cursor-not-allowed opacity-70' : 'hover:scale-110 hover:bg-shampoo'
                  }`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                      Loading...
                    </div>
                  ) : (
                    'Subscribe'
                  )}
                </button>
              </form>
            </div>
          </div>

          <div className="mt-10 text-center border-t border-gray-300 pt-6">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} YYDTS. All rights reserved.
            </p>
            <p className="text-xs text-gray-500 mt-2">
              Powered by <span className="font-semibold text-gray-700">SurafelK</span>.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
