import React from 'react';
import hair_oil from '../images/products/hair-care/Hair-Oil.png';
import greenBG from '../images/patterns/TDwebbackground-01.png'
import hairOilProdBG from '../images/prod_ngs/TheraDermBackgroundPictures-03.png'
function Hairoil() {
  return (
    <div>
   

    <div className="py-16 border-x-8 border-white">
      {/* Header Section */}

       <div className='' >
        <div className='flex flex-col justify-center items-center ' >
          <div className='pt-5' >
            <h1 className='text-orange_text md:text-6xl font-semibold' > HAIR OIL </h1>
          </div>
          <div className='mb-5   w-96 h-6 rounded-lg flex flex-col justify-center align-middle py-4 ' >
            <h1 className='text-orange_text md:text-sm  text-center capitalize ' > AVOCADO WITH ALMOND </h1>
          </div>
        </div>
      </div>
 
   
      {/* Avocado Conditioner Section */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden "
         style={{  backgroundImage: `url(${hairOilProdBG})`,
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat',
         backgroundPosition: 'center',}} 
      >
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={hair_oil}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={hair_oil}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={hair_oil}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-white text-xs'>Available in <span className='text-orange_text' >75ml</span>, <span className='text-orange_text' >200ml</span> & <span className='text-orange_text' >300ml</span></h2>
            </div>
        </div>
        {/* Text Section */}
        <div className='shadow-xl flex flex-col items-center justify-center ' >
            <div className=" flex flex-col p-10 justify-center items-center align-middle">

                <div className='flex flex-col justify-center items-center align-middle ' >
                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-6 text-center">
                        Avocado Hair Oil
                    </h2>
                    <p className="text-white text-sm px-5">
                        THERA DERM’s <span className='text-orange_text font-semibold' >Avocado Hair Oil</span>,enriched with Sweet Almond
                        Oil, Coconut Oil and Vitamin E. Deeply nourishes the hair, leaving it
                        moisturized and shiny
                    </p>
                </div>
            </div>

            <div className='flex justify-center align-bottom items-end relative font-playfair'>
                    <div className=' flex justify-center items-center align-bottom' >
                    <h2 className='text-white text-lg '> "for <span className='text-orange_text'> strong </span> & <span className='text-orange_text' > healthy </span> hair" </h2>
                    </div>
            </div>

        
        </div>
        

        
      </div>

    </div>
    </div>
  );
}

export default Hairoil;
