import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from "framer-motion";
import slider1 from '../src/images/slider/TD Slider for Web 1920px x 1080px-04.png';
import slider2 from '../src/images/slider/TD Slider for Web 1920px x 1080px-03.png';
import slider3 from '../src/images/slider/TD Slider for Web 1920px x 1080px-02.png';
import slider4 from '../src/images/slider/TD Slider for Web 1920px x 1080px-01.png';
import trueBeauty from './images/logo/unlock.png'; 
import Footer from './components/Fotter';
import Home from './pages/Home';
import ContactUs from './pages/Contactus';
import "@fontsource/playfair-display"; // Defaults to regular weight
import { useEffect, useState } from 'react';
import bg from '../src/images/patterns/welcome.png';


function App() {
  const slides = [slider4, slider3, slider2, slider1];
  const [flash, setFlashOpen] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFlashOpen(false);
    }, 3000);
    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);

  return (
    flash ? (
      <div className="flex flex-col items-center justify-center h-screen" 
      style={{ backgroundImage: `url(${bg})` }}
      >
        {/* Animated Image */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={trueBeauty} alt="Logo of True Beauty" className="md:h-96 md:w-96 h-60 w-60 " />
        </motion.div>

        {/* Animated Text */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }} // Delayed by 1 second
        >
          <h1 className="text-white md:text-6xl text-4xl text-center font-playfairItalic italic">
            "Unlock Your Inner Beauty"
          </h1>
        </motion.div>
      </div>
    ) : (
      <Router>
        <div className="w-full">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contactus" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    )
  );
}

export default App;
