import React, { useState, useEffect } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

function Carousel({ slides }) {  
    const [current, setCurrent] = useState(0); // Initialize the current slide to 0

    // Previous slide handler
    const previousSlide = () => {
        if (current === 0) setCurrent(slides.length - 1);
        else setCurrent(current - 1);
    };

    // Next slide handler
    const nextSlide = () => {
        if (current === slides.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };

    // Automatically change slides every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide(); // Move to the next slide
        }, 5000); // Change slide every 5 seconds

        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, [current]); // Dependency array ensures it runs when `current` changes

    return (
        <div className="overflow-hidden relative w-full max-h-screen">
        <div
            className="flex transition ease-out duration-500 w-full"
            style={{
                transform: `translateX(-${current * 100}%)`, // Handles the slide movement
            }}
        >
            {slides.map((s, index) => (
                <img
                    key={index}
                    src={s}
                    alt={`slide-${index}`}
                    className="w-full h-full object-cover"
                    style={{ filter: 'brightness(0.75) contrast(1.2)' }} // Adjust the filter as needed
                />
            ))}
        </div>
    
        {/* Navigation Buttons */}
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-between items-center text-2xl px-4 md:px-20 text-white">
            <button
                onClick={previousSlide}
                className="p-2 bg-black rounded-full opacity-50 hover:opacity-100 transition duration-300"
            >
                <BsFillArrowLeftCircleFill />
            </button>
            <button
                onClick={nextSlide}
                className="p-2 bg-black rounded-full opacity-50 hover:opacity-100 transition duration-300"
            >
                <BsFillArrowRightCircleFill />
            </button>
        </div>
    </div>
    
    );
}

export default Carousel;
