import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from '../components/carousel';
import AboutTheradem from '../components/about_company';
import Catalogues from '../components/Catalogues';
import Hair_care from '../components/Shampoo';
import BodycareCatalog from '../components/BodycateCatalog';
import Conditioner from '../components/Conditioner';
import Hairoil from '../components/Hairoil';
import Glycerin from '../components/Glycerin';
import FragranceGlycerin from '../components/FragranceGlycerin';
import BodyLotion from '../components/Body_Lotion';
import Bodywash from '../components/Body_wash';
import Handwash from '../components/Hand_Wash';
import Footer from '../components/Fotter';

import slider1 from '../images/slider/TD Slider for Web 1920px x 1080px-04.png';
import slider2 from '../images/slider/TD Slider for Web 1920px x 1080px-03.png';
import slider3 from '../images/slider/TD Slider for Web 1920px x 1080px-02.png';
import slider4 from '../images/slider/TD Slider for Web 1920px x 1080px-01.png';
import logo from '../images/logo/TD Green Logo.png';
import trueBeauty from '../images/logo/unlock.png';
import vid from '../images/Vids/Waterfall Clip(720P_HD).mp4';
import Hairpattern from '../images/patterns/TDwebbackground-01.png';
import Bodypattern from '../images/patterns/TDwebbackground-02.png';
import { motion } from "framer-motion";

function Home() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [flash, setFlashOpen] = useState(true);

  const slides = [slider4, slider3, slider2, slider1];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFlashOpen(false);
    }, 3000);
    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);

  return (
    <div className="w-full">
      {/* Navigation */}
      <div className="w-full sticky top-0 z-50">
        <nav className="flex items-center justify-between bg-white p-4 shadow-md">
          {/* Brand Section */}
          <div className="flex items-center justify-between w-full lg:w-auto">
            <img
              src={logo}
              alt="TheraDerm Logo"
              className="h-16 w-16 md:h-20 md:w-20 object-contain"
            />
            <button
              className="lg:hidden text-gray-900 focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Mobile Menu */}
          <div
            className={`fixed top-0 left-0 h-full w-64 bg-white transform transition-transform duration-300 ease-in-out ${
              isMenuOpen ? 'translate-x-0' : '-translate-x-full'
            } lg:static lg:translate-x-0 lg:flex lg:space-x-6 lg:items-center`}
          >
            <ul className="flex flex-col lg:flex-row lg:space-x-20 gap-6 p-6 lg:p-0">
              <li>
                <a
                  href="#home"
                  className="text-gray-700 hover:text-green-600 font-medium transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#theraderm"
                  className="text-gray-700 hover:text-green-600 font-medium transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#products"
                  className="text-gray-700 hover:text-green-600 font-medium transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Products
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Us */}
          <div className="hidden lg:block">
            <button
              onClick={() => navigate('/contactus')}
              className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-green-700 transition duration-200"
            >
              Contact Us
            </button>
          </div>
        </nav>
      </div>

      {/* Home Section */}
      <div id="home">
        <Carousel slides={slides} />
      </div>

      {/* About Section */}
      <div id="theraderm">
        <AboutTheradem />
      </div>

      {/* Video Background Section */}
      <div className="relative w-full h-screen flex justify-center items-center">
        <div className="absolute top-0 left-0 w-full h-full">
          <video
            className="w-full h-full object-cover"
            src={vid}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
        <div className="relative z-10 flex flex-col md:flex-row justify-center items-center">
          <a href="#hair_care" className="m-4">
            <Catalogues />
          </a>
          <a href="#body_care" className="m-4">
            <BodycareCatalog />
          </a>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black/40"></div>
      </div>

      {/* Products Sections */}
      <div
        id="hair_care"
        className="bg-cover bg-center"
        style={{ backgroundImage: `url(${Hairpattern})` }}
      >
        <Hair_care />
        <Conditioner />
        <Hairoil />
      </div>

      <div
        id="body_care"
        className="bg-cover bg-center"
        style={{ backgroundImage: `url(${Bodypattern})` }}
      >
        <Glycerin />
        <FragranceGlycerin />
        <BodyLotion />
        <Bodywash />
        <Handwash />
      </div>

    
    </div>
  );
}

export default Home;
