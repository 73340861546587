import React from 'react'
import bodycare from '../images/catalogs/TD Web 1200px x 800px-02.png'

function BodycareCatalog() {
  return (
    <div className='w-full md:h-screen flex justify-center items-center'>
      <div className=' px-4'>
    
        {/* Card 2 */}
        <div className='w-full max-w-xl bg-white shadow-xl rounded-xl overflow-hidden transform transition-all duration-300  hover:shadow-2xl'>
          <img src={bodycare} alt="Bodycare" className='w-full h-56 sm:h-96 object-cover transition-transform duration-500 hover:scale-110 hover:cursor-pointer' />
          <div className='p-6'>
            <h3 className='text-2xl font-normal text-gray-800 text-center capitalize' >Body care Essentials</h3>
            <p className='text-base text-gray-500 mt-3 text-center '>
            "Nurturing the body is the first step to nurturing the soul."
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BodycareCatalog
