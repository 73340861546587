import React from 'react';
import pure_gly from '../images/products/body-care/Pure-Glycerin.png';
import frag_glyc from '../images/products/body-care/Perfumed-Glycerin.png';
import pattern from '../images/patterns/TDwebbackground-02.png'
import glyProdBG from '../images/prod_ngs/TheraDermBackgroundPictures-05.png'
function FragranceGlycerin() {
  return (
    <div className=" py-16  border-x-8 border-glycerin_pink_txt " >
   <div className='bg-' >
        <div className='flex flex-col justify-center items-center ' >
          <div className='pt-5' >
            <h1 className='text-white md:text-6xl font-semibold' > BODY GLYCERIN </h1>
          </div>
          <div className='mb-5   w-96 h-6 rounded-lg flex flex-col justify-center align-middle py-4 ' >
            <h1 className='text-white md:text-sm  text-center capitalize ' >  SLIGHTLY PERFUMED </h1>
          </div>
        </div>
      </div>
    <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden"
    style={{  backgroundImage: `url(${glyProdBG})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'}} 
    >
        {/* Text Section */}
      <div className='flex flex-col justify-center  shadow-xl w-full md:w-[600px] '
             
      >
        <div className="p-10 text-justify">
            <h2 className="text-3xl md:text-4xl font-bold text-glycerin_pink_txt mb-6 text-center">
                BODY GLYCERIN
            </h2>
           <h1 className='text-glycerin_pink_txt mb-3  text-center capitalize ' > SLIGHTLY PERFUMED </h1>
          
            <p className="text-gray-700 text-sm px-5">
            THERA DERM’s <span className='text-glycerin_pink_txt font-semibold' > Perfumed Body Glycerin </span>softens and deeply
            moisturizes the skin, leaving the skin protected & slightly scented.
            </p>
        </div>


        <div className='flex justify-center align-bottom items-end relative '>
                    <div className=' flex justify-center items-center align-bottom font-playfair' >
                    <h2 className='text-gray-700 text-lg '> "for <span className='text-glycerin_pink_txt'> silky skin </span> with a touch of <span className='text-glycerin_pink_txt' > fragrance </span> " </h2>
                    </div>
            </div>


      </div>
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={frag_glyc}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={frag_glyc}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={frag_glyc}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-white text-xs'>Available in <span className='text-glycerin_pink_txt' > 75ml, </span> <span className='text-glycerin_pink_txt' > 200ml </span>  & <span className='text-glycerin_pink_txt' > 500ml </span> </h2>
            </div>
        </div>
        
      </div>
   </div>

  )
}

export default FragranceGlycerin