import React from 'react'
import haircare from '../images/catalogs/TD Web 1200px x 800px-01.png'
import bodycare from '../images/catalogs/TD Web 1200px x 800px-02.png'

function Catalogues() {
  return (
    <div 
      className='w-full md:h-screen flex justify-center items-center '
      style={{
        backgroundImage: 'url("https://youtu.be/oYEtLQ3lEH0")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom',
      }}
    >
      <div className='px-4'>
        {/* Card 1 */}
        <div className='w-full max-w-xl bg-white shadow-xl rounded-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl'>
          <img 
            src={haircare} 
            alt="Bodycare" 
            className='w-full h-56 sm:h-96 object-cover transition-transform duration-500 hover:scale-110 hover:cursor-pointer' 
          />
          <div className='p-6'>
            <h3 className='text-2xl font-normal text-gray-800 text-center capitalize'>
              Hair care Essentials
            </h3>
            <p className='text-base text-gray-500 mt-3 text-center'>
              “Invest in your hair, it’s the crown you never take off.”
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Catalogues;
