import React from 'react';
import pure_gly from '../images/products/body-care/Pure-Glycerin.png';
import frag_glyc from '../images/products/body-care/Perfumed-Glycerin.png';
import pattern from '../images/patterns/TDwebbackground-02.png'
import glyProdBG from '../images/prod_ngs/TheraDermBackgroundPictures-04.png'
function Glycerin() {
  return (
    <div>
   

    <div className=" py-16  border-x-8 border-conditioner_border" >
      {/* Header Section */}

       <div className='bg-' >
        <div className='flex flex-col justify-center items-center ' >
          <div className='pt-5' >
            <h1 className='text-glycerin_txt md:text-6xl font-semibold' > BODY GLYCERIN </h1>
          </div>
          <div className='mb-5   w-96 h-6 rounded-lg flex flex-col justify-center align-middle py-4 ' >
            <h1 className='text-glycerin_txt md:text-sm  text-center capitalize ' > PURE & NATURAL </h1>
          </div>
        </div>
      </div>
 
   
      {/* Avocado Conditioner Section */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden "
             style={{  backgroundImage: `url(${glyProdBG})`,
             backgroundSize: 'cover',
             backgroundRepeat: 'no-repeat',
             backgroundPosition: 'left bottom'}} 
      >
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={pure_gly}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={pure_gly}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={pure_gly}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-glycerin_txt text-xs'>Available in <span className='text-orange_text font-bold' >7ml,</span> <span className='text-orange_text font-bold' >200ml</span> & <span className='text-orange_text font-bold' >500ml</span></h2>
            </div>
        </div>
        {/* Text Section */}
        <div className='shadow-xl flex flex-col items-center justify-center ' >
            <div className=" flex flex-col p-10 justify-center items-center align-middle">

                <div className='flex flex-col justify-center items-center align-middle ' >
                    <h2 className="text-3xl md:text-4xl font-bold text-glycerin_txt mb-6 text-center">
                        Body Glycerin
                    </h2>
                    <p className="text-white text-sm px-5">
                        THERA DERM’s <span className='text-glycerin_txt font-semibold capitalize' > pure & natural body glycerin </span>,Pure & Natural Body Glycerin softens and
                        deeply moisturizes the skin, leaving the skin protected.
                    </p>
                </div>
            </div>

            <div className='flex justify-center align-bottom items-end relative font-playfair '>
                    <div className=' flex justify-center items-center align-bottom' >
                    <h2 className='text-white text-lg '> "for <span className='text-glycerin_txt'> smooth </span> & <span className='text-glycerin_txt' > moisturized </span> skin" </h2>
                    </div>
            </div>

        
        </div>
        

        
      </div>

   
    </div>
    </div>
  );
}

export default Glycerin ;
