import React from 'react';
import logo from '../images/logo/TD Green Logo.png';
import {motion} from 'framer-motion'

function AboutTheradem() {
  return (
    <div className="flex justify-center bmd:p-20">
      <div className="flex flex-col items-center justify-center bg-white p-8 rounded-xl shadow-2xl w-full max-w-4xl">
        
        {/* Logo Section */}
        <div className="mt-5">
          <img
            src={logo}
            alt="TheraDerm Logo"
            className="w-32 h-32 object-contain mx-auto transition-transform duration-300 "
          />
        </div>
        


        {/* Description Section */}
        <motion.div
        
         className="flex items-center align-middle max-w-xl ">
            <motion.p
               initial={{ opacity: 0, y: 50 }}
               whileInView={{ opacity: 1, y: 0 }}
               viewport={{ once: true, amount: 0.5 }}
               transition={{ duration: 1 }}
             className=" text-base sm:text-xs md:text-sm   leading-relaxed  text-justify  transition-transform duration-300  py-20">
                    Inspired by the Greek words for “earth” (thera) and
                    “skin” (derma),Thera Derm blends purity with efficacy.
                    Our products nourish and revitalize, combining
                    botanical extracts, essential oils, and advanced active
                    ingredients to enhance your natural beauty.
                    Thera Derm offers a curated collection of pure, natural
                    personal care essentials focused on deep nourishment,
                    hydration, and protection. Our range includes various
                    formulations for hair and body, enriched with white
                    honey, almond oil, pure glycerin, avocado oil coconut
                    oil and various other ingredients to support your skin
                    and hair’s unique needs.
                    By integrating nature’s therapeutic elements into every
                    formulation, Thera Derm represents a holistic approach
                    to well-being. We place a high value on establishing
                    top-notch products that highlight your inherent beauty
                    and promote a self-care way of living.
                    With Thera Derm, you’re not just using products, you’re
                    embracing a brand that truly cares about your journey
                    to radiance while delivering the perfect blend of quality
                    and affordability.
            </motion.p>
        </motion.div>

       
      </div>
    </div>
  );
}

export default AboutTheradem;
