import React from 'react';
import avo_condi from '../images/products/hair-care/Avocado-Conditioner.png';
import coconut_condi from '../images/products/hair-care/Coco-Conditioner.png';

import avo_condbg from '../images/prod_ngs/TheraDermBackgroundPictures-01.png'
import coco_condbg from '../images/prod_ngs/TheraDermBackgroundPictures-02.png'
function Conditioner() {
  return (
    <div>
   

    <div className=" py-16 border-x-8 border-conditioner_border"  
    >
      {/* Header Section */}

       <div className='bg-' >
        <div className='flex flex-col justify-center items-center ' >
          <div className='pt-5' >
            <h1 className='text-white md:text-6xl font-semibold' > CONDITIONER </h1>
          </div>
          <div className='mb-5   w-96 h-6 rounded-lg flex flex-col justify-center align-middle py-4 ' >
            <h1 className='text-orange_text md:text-sm  text-center capitalize ' > In avocado & coconut variants </h1>
          </div>
        </div>
      </div>
 
   
      {/* Avocado Conditioner Section */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden "
       style={{  backgroundImage: `url(${avo_condbg})`,
       backgroundSize: 'cover',
       backgroundRepeat: 'no-repeat',
       backgroundPosition: 'left bottom',}} 
      >
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={avo_condi}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={avo_condi}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={avo_condi}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-white text-xs'>Available in <span className='text-orange_text' >300ml</span> &  <span className='text-orange_text' >500ml</span>  </h2>
            </div>
        </div>
        {/* Text Section */}
        <div className=' shadow-xl flex flex-col items-center justify-center ' >
            <div className=" flex flex-col p-10 justify-center items-center align-middle">

                <div className='flex flex-col justify-center items-center align-middle ' >
                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-6 text-center">
                        Avocado Conditioner
                    </h2>
                    <p className="text-white text-sm px-5">
                        THERA DERM’s <span className='text-orange_text font-semibold' >Avocado Conditioner</span>, enriched with the goodness of
                        Aloe Vera, is a nourishing detangler that provides slip and lubrication to
                        the hair, preventing hair breakage and split ends. It restores strength and
                        shine to weak and damaged hair.
                    </p>
                </div>
            </div>

            <div className='flex justify-center align-bottom items-end relative font-playfair'>
                    <div className=' flex justify-center items-center align-bottom' >
                    <h2 className='text-white text-lg '> "for <span className='text-orange_text'> soft </span> & <span className='text-orange_text' > silky </span> hair" </h2>
                    </div>
            </div>

        
        </div>
        

        
      </div>

      {/* Coconut Shampoo Section */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 shadow-lg mx-auto max-w-6xl overflow-hidden"
              style={{  backgroundImage: `url(${coco_condbg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',}} 
      >
        {/* Text Section */}
      <div className='flex flex-col justify-center  shadow-xl w-full md:w-[600px] ' >
        <div className="p-10 text-justify">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6 text-center">
                Coconut Conditioner
            </h2>
            <p className="text-orange_text text-sm px-5">
            THERA DERM’s <span className='text-white font-semibold' > Coconut Conditioner </span>, enriched with Coconut Oil,
                is a nourishing detangler that provides slip and lubrication to the hair
                preventing hair breakage and split ends. It restores strength and shine to
                weak and damaged hair.
            </p>
        </div>


        <div className='flex justify-center align-bottom items-end relative font-playfair'>
                    <div className=' flex justify-center items-center align-bottom' >
                    <h2 className='text-white text-lg '> "for <span className='text-orange_text'> soft </span> & <span className='text-orange_text' > silky </span> hair" </h2>
                    </div>
            </div>


      </div>
        {/* Image Section */}
        <div className="flex flex-col items-center justify-center p-10 space-y-5 w-full md:w-[600px] ">
          <div className='flex items-center justify-center align-middle' >
            <img
                src={coconut_condi}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={coconut_condi}
                alt="Coconut Shampoo"
                className="h-60 md:h-80 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
              <img
                src={coconut_condi}
                alt="Coconut Shampoo"
                className="h-40 md:h-60 w-auto object-contain transition-transform transform hover:scale-105 hover:rotate-2 mx-2"
              />
          </div>
          <div className='flex justify-center items-end relative'>
              <h2 className='text-white text-xs'>Available in <span className='text-orange_text' >300ml</span> & <span className='text-orange_text' >500ml</span></h2>
            </div>
        </div>

      </div>
    </div>
    </div>
  );
}

export default Conditioner;
